<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <b>首页</b>
                </el-breadcrumb-item>
                <el-breadcrumb-item>内容管理</el-breadcrumb-item>
                <el-breadcrumb-item>美食管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/merchant/list' }">店铺</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">
            <el-form ref="form" :model="form" label-width="150px" :rules="rules" class="el-form-self">

                <el-row>
                    <el-col>
                        <el-form-item label="名称:" prop="merchantName">
                            <el-input v-model="form.merchantName" maxlength="50" show-word-limit size="small"
                                      auto-complete="off" placeholder="请输入店铺名称" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="位置:" prop="merchantLocation">
                            <el-input v-model="form.merchantLocation" auto-complete="off" placeholder="请输入店铺位置信息"
                                      clearable maxlength="100" show-word-limit size="small"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="电话:" prop="merchantPhone">
                            <el-input v-model="form.merchantPhone" auto-complete="off" placeholder="请输入店铺电话" clearable
                                      maxlength="13" show-word-limit size="small"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <!--<el-row>-->
                    <!--<el-col>-->
                        <!--<el-form-item label="简介:" prop="merchantIntroduction">-->
                            <!--<el-input v-model="form.merchantIntroduction" auto-complete="off" placeholder="请输入店铺简介"-->
                                      <!--clearable maxlength="100" show-word-limit size="small"></el-input>-->
                        <!--</el-form-item>-->
                    <!--</el-col>-->
                <!--</el-row>-->

                <el-row>
                    <el-col>
                        <el-form-item label="推荐指数:" prop="merchantLevel">
                            <el-rate v-model="form.merchantLevel" show-score text-color="#ff9900" score-template="{value}颗星"></el-rate>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="列表缩略图片:" prop="merchantPhoto" class="is-required">
                            <el-upload class="homeBannerImage-uploader" :action="uploadFileURL" :show-file-list="false" :on-success="handleMerchantImageSuccess" :before-upload="beforeMerchantImageUpload">
                                <img v-if="merchantPhoto" :src="merchantPhoto" class="homeBannerImage"/>
                                <i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip">注意：点击 + 上传.jpg, .png后缀图片文件。图片尺寸为220px x 220px,大小不超过5MB。
                                </div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="图片或者视频:" prop="merchantVideo" class="is-required">
                            <el-upload class="upload-demo" :action="uploadFileURL" :multiple="false" :limit="1" :on-preview="handleImagePreview" :on-success="handleImageSuccess" :before-upload="beforeImageUpload" :on-remove="handleImageRemove" :on-exceed="handleImageExceed" :file-list="merchantImageUrlList">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">注意：图片上传.jpg,.png后缀图片文件，图片尺寸大小为750px x 375px，大小不能超过5MB；视频上传大小不超过20MB；图片或视频只可以上传一个</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="内容:" prop="merchantContent">
                            <Editor id="tinymce" v-model="form.merchantContent" :init="editorInit"></Editor>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="置顶:" prop="merchantTop">
                            <el-radio-group v-model="form.merchantTop">
                                <el-radio label="0">是</el-radio>
                                <el-radio label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="推荐:" prop="merchantRecommend">
                            <el-radio-group v-model="form.merchantRecommend">
                                <el-radio label="0">是</el-radio>
                                <el-radio label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="form-buttons">
                    <el-form-item class="el-form-item-self">
                        <el-button type="primary" @click="$router.go(-1)">返回</el-button>
                        <el-button type="primary" @click="onSubmit">保存</el-button>
                        <el-button type="primary" @click="temporarySubmit">发布</el-button>
                    </el-form-item>
                </el-row>

                <el-dialog :visible.sync="merchantDialogVisible" >
                    <video v-if="uploadType==='video/mp4'" v-for="(video,index) in merchantImageUrlArray" :key="index" width="100%" height="100%" :src="video" controls></video>
                    <el-image v-else v-for="(image,index) in merchantImageUrlArray" :key="index" style="width: 200px; height: 200px" :src="image" :preview-src-list="merchantImageUrlArray" alt=""></el-image>
                </el-dialog>
            </el-form>
        </el-col>
    </el-row>
</template>
<script>
    import {reqAddMerchant} from "../../../api/merchant-api";
    import {downloadFileURL, uploadFileURL} from "../../../api/system-api";
    import {SERVER_HOST} from "../../../common/portConfig";
    import tinymce from "tinymce/tinymce";
    import "tinymce/themes/silver/theme";
    import Editor from "@tinymce/tinymce-vue";
    import "tinymce/plugins/code";
    import "tinymce/plugins/print";
    import "tinymce/plugins/preview";
    import "tinymce/plugins/importcss";
    import "tinymce/plugins/searchreplace";
    import "tinymce/plugins/autolink";
    import "tinymce/plugins/autosave";
    import "tinymce/plugins/save";
    import "tinymce/plugins/directionality";
    import "tinymce/plugins/visualblocks";
    import "tinymce/plugins/visualchars";
    import "tinymce/plugins/fullscreen";
    import "tinymce/plugins/image";
    import "tinymce/plugins/media";
    import "tinymce/plugins/link";
    import "tinymce/plugins/codesample";
    import "tinymce/plugins/table";
    import "tinymce/plugins/charmap";
    import "tinymce/plugins/hr";
    import "tinymce/plugins/pagebreak";
    import "tinymce/plugins/nonbreaking";
    import "tinymce/plugins/anchor";
    import "tinymce/plugins/toc";
    import "tinymce/plugins/insertdatetime";
    import "tinymce/plugins/advlist";
    import "tinymce/plugins/lists";
    import "tinymce/plugins/wordcount";
    import "tinymce/plugins/imagetools";
    import "tinymce/plugins/textpattern";
    import "tinymce/plugins/noneditable";
    import "tinymce/plugins/help";
    import "tinymce/plugins/charmap";
    import "tinymce/plugins/quickbars";
    import "tinymce/plugins/lists";
    import "tinymce/plugins/media";
    import "tinymce/icons/default/icons";
    import "../../../../public/static/tinymce/langs/zh_CN";

    export default {
        components: {
            Editor,
        },
        data() {
            return {
                videoUrlDialogVisible: false,
                imageUrlDialogVisible: false,
                rate: null,
                imageUrl: "",
                imageUrlArray: [],
                imageUrlList: [],
                uploadType: "",
                uploadFileURL: uploadFileURL,
                downloadFileURL: downloadFileURL,
                addPath: "/merchant/list",
                defaultMsg: "",
                merchantPhoto: "",
                form: {
                    merchantName: "",
                    merchantPhone: "",
                    merchantPhoto: "",
                    merchantLocation: "",
                    merchantPublisher: "",
                    merchantLevel: null,
                    merchantStatus: "",
                    merchantTop:'1',
                    merchantRecommend:'1',
                    merchantContent:'',
                    merchantVideo:'',
                },
                merchantLevel: "",
                rules: {
                    merchantName: [
                        {required: true, message: "请输入店铺名称", trigger: "blur"},
                        {min: 1, max: 50, message: "名称不能超过50个字符 ", trigger: "blur",},
                    ],
                    merchantLocation: [
                        {required: true, message: "请输入店铺位置", trigger: "blur"},
                        {min: 1, max: 50, message: "名称不能超过50个字符 ", trigger: "blur",},
                    ],
                    merchantPhone: [
                        {required: true, message: '请输入店铺电话', trigger: 'blur'},
                        {pattern: /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$|^(400-)(\d{7})$|^1[3|4|5|7|8|9][0-9]{9}$/, message: '请输入正确的店铺电话', trigger: 'blur'},
                    ],
                    merchantLevel: [
                        {required: true, message: "请选择推荐指数", trigger: "change"},
                    ],
                    merchantPhoto: [
                        {required: true, message: "请选择要上传的店铺门头照"},
                    ],
                    merchantTop: [
                        { required: true, message: "请选择是否置顶", trigger: "blur" }
                        ],
                    merchantRecommend: [
                        { required: true, message: "请选择是否推荐", trigger: "blur" }
                    ],
                    merchantContent: [
                        { required: true, message: "请输入内容", trigger: "blur" },
                        {min: 1, max: 20000, message: "内容不能超过20000个字符 ", trigger: "blur",},
                    ],
                },
                editorInit: {
                    language_url: "/static/tinymce/langs/zh_CN.js",
                    language: "zh_CN",
                    skin_url: "/static/tinymce/skins/ui/oxide",
                    content_css: "/static/tinymce/skins/content/default/content.css",
                    height: 600,
                    font_formats:
                        "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
                    plugins:
                        "code print preview indent2em importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars media",
                    toolbar:
                        "undo redo |code bold italic underline strikethrough indent2em | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                    file_picker_types: "media file",
                    // images_upload_url: '/editorfile/uploadFile',
                    autosave_ask_before_unload: false,
                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "media") {
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            //你可以给input加accept属性来限制上传的文件类型
                            //例如：input.setAttribute('accept', '.jpg,.png');
                            input.setAttribute("accept", ".mp4");
                            input.click();
                            input.oninput = function () {
                                var file = this.files[0];
                                var xhr, formData;
                                //console.log(file.name);
                                xhr = new XMLHttpRequest();
                                xhr.open("POST", SERVER_HOST + "/editorfile/uploadVideoFile");
                                // xhr.withCredentials = true ;
                                xhr.onload = function () {
                                    var json;
                                    if (xhr.status != 200) {
                                        alert("上传视频失败");
                                        return false;
                                    }
                                    json = JSON.parse(xhr.responseText);
                                    if (!json || typeof json.location != "string") {
                                        alert("上传视频失败");
                                        return false;
                                    }
                                    callback(json.location);
                                };
                                formData = new FormData();
                                if (file.size / 1024 / 1024 > 20) {
                                    alert("上传视频不能超过20MB");
                                    return false;
                                }
                                formData.append("file", file, file.name);
                                xhr.send(formData);
                            };
                        } else if (meta.filetype === "file") {
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            //你可以给input加accept属性来限制上传的文件类型
                            //例如：input.setAttribute('accept', '.jpg,.png');
                            input.setAttribute("accept", ".xls,.xlsx,.doc,.docx,.pdf");
                            input.click();
                            input.oninput = function () {
                                var file = this.files[0];
                                var xhr, formData;
                                //console.log(file.name);
                                xhr = new XMLHttpRequest();
                                xhr.open("POST", SERVER_HOST + "/editorfile/uploadFile");
                                xhr.withCredentials = true;
                                xhr.onload = function () {
                                    var json;
                                    if (xhr.status != 200) {
                                        alert("上传失败");
                                        return false;
                                    }
                                    json = JSON.parse(xhr.responseText);
                                    if (!json || typeof json.data != "string") {
                                        alert("上传失败");
                                        return false;
                                    }
                                    callback(json.data, { text: "" });
                                };
                                formData = new FormData();
                                if (file.size / 1024 / 1024 > 20) {
                                    alert("上传失败");
                                    return false;
                                }
                                formData.append("file", file, file.name);
                                xhr.send(formData);
                            };
                        }
                    },
                    images_upload_handler: function (blobInfo, succFun, failFun) {
                        var xhr, formData;
                        var file = blobInfo.blob(); //转化为易于理解的file对象
                        xhr = new XMLHttpRequest();
                        xhr.open("POST", SERVER_HOST + "/editorfile/uploadFile", true);
                        xhr.withCredentials = true;
                        xhr.onload = function () {
                            var json;
                            if (xhr.status != 200) {
                                failFun("上传图片失败");
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            if (!json || typeof json.data != "string") {
                                failFun("上传图片失败");
                                return;
                            }
                            succFun(json.data);
                        };
                        formData = new FormData();
                        formData.append("file", file, file.name);
                        xhr.send(formData);
                    },
                },
                merchantImageUrl:'',
                merchantDialogVisible:false,
                merchantImageUrlList:[],
                merchantImageUrlArray:[],
            };
        },

        methods: {
            beforeImageUpload(file) {
                const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'||file.type==='video/mp4');
                var fileSize=5;
                if(file.type==='video/mp4'){
                    fileSize=20;
                }
                const isLt20M = file.size / 1024 / 1024 < fileSize;
                this.uploadType=file.type;
                if (!isValidType) {
                    this.$message.error('不支持该类型的文件上传。');
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error('上传文件大小不能超过 '+fileSize+'MB!');
                    return false;
                }
                return true;
            },
            handleImagePreview(file) {
                this.merchantImageUrl = downloadFileURL + file.response.data + "/";
                this.merchantDialogVisible = true;
            },
            handleImageSuccess(response, file, fileList) {
                var that = this;
                if (response.code === 1) {
                    that.merchantImageUrlList = fileList;
                    that.merchantImageUrlArray.push(downloadFileURL + response.data + "/");
                }
            },
            handleImageRemove(file, fileList) {
                var index = this.merchantImageUrlArray.indexOf(downloadFileURL + file.response.data.substring(file.response.data.substring(0, file.response.data.length - 2).lastIndexOf("/") + 1, file.response.data.length) + "/");
                if (index !== -1) {
                    this.merchantImageUrlArray.splice(index, 1);
                }
            },
            handleImageExceed(files, fileList) {
                this.$message.warning(
                    `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
                    files.length + fileList.length
                        } 个文件`
                );
            },
            beforeMerchantImageUpload(file) {
                const isValidType =
                    file.type === "image/jpeg" ||
                    file.type === "image/jpg" ||
                    file.type === "image/png";
                const isLt1M = file.size / 1024 / 1024 < 5;
                if (!isValidType) {
                    this.$message.error("不支持改类型的文件上传。");
                }
                if (!isLt1M) {
                    this.$message.error("上传文件大小不能超过 5MB!");
                }
                return isValidType && isLt1M;
            },
            handleMerchantImageSuccess(response) {
                if (response.code === 1) {
                    this.form.merchantPhoto = response.data;
                    this.merchantPhoto = downloadFileURL + response.data + "/";
                    console.log(this.merchantPhoto);
                    this.$refs.form.validateField("merchantPhoto");
                }
            },
            onSubmit() {
                var that = this;
                that.form.merchantStatus = "1";
                that.form.merchantPublisher = sessionStorage.getItem("Authorization");
                delete that.form["createTime"];
                delete that.form["updateTime"];

                var imageUrls=[];
                that.merchantImageUrlArray.forEach(function (obj) {
                    imageUrls.push(obj.substring(obj.substring(0, obj.length - 2).lastIndexOf('/') + 1, obj.length - 1));
                });
                that.form.merchantVideo=imageUrls.join(',');
                if(!that.form.merchantVideo) {
                    that.$message({
                        message: '请上传图片或视频',
                        type: 'warning'
                    });
                    return;
                }
                var para = Object.assign({}, that.form);
                reqAddMerchant(para).then((res) => {
                    if (res.data.code === 1) {
                        that.$message({
                            message: "保存成功",
                            type: "success",
                        });
                        that.$router.push({path: that.addPath});
                    } else {
                        that.$message({
                            message: "保存失败",
                            type: "error",
                        });
                    }
                });
            },
            temporarySubmit() {
                var that = this;
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        that.form.merchantStatus = "0";
                        that.form.merchantPublisher = sessionStorage.getItem("Authorization");
                        delete that.form["createTime"];
                        delete that.form["updateTime"];
                        var imageUrls=[];
                        that.merchantImageUrlArray.forEach(function (obj) {
                            imageUrls.push(obj.substring(obj.substring(0, obj.length - 2).lastIndexOf('/') + 1, obj.length - 1));
                        });
                        that.form.merchantVideo=imageUrls.join(',');
                        var para = Object.assign({}, that.form);
                        reqAddMerchant(para).then((res) => {
                            if (res.data.code === 1) {
                                that.$message({
                                    message: "发布成功",
                                    type: "success",
                                });
                                that.$refs.form.resetFields();
                                that.$router.push({path: that.addPath});
                            } else {
                                that.$message({
                                    message: "发布失败",
                                    type: "error",
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
        },
        mounted() {
            tinymce.init({});
        },
    };
</script>
